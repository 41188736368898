import React from 'react';
import { Link } from 'react-router-dom';

function Flxifooter() {
    return (
        <>
        <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="footer-info">
                  <h3>ANSO</h3>
                  <p className="pb-3">
                    <em>
                    "Anso's quality helps your success quantity"
                    </em>
                  </p>
            
                </div>
              </div>
              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Address</h4>   
                  <p>
                  Anso Flexi Pack <br />
                  6 / 1442 B , Srivilliputhur Road,<br /> 
                  Satchiyapuram,<br/>
                  Sivakasi - 626 124. <br/>
                    <strong>Phone:</strong> +91 98438 66661 , 04562-255553
                    <br />
                    <strong>Email:</strong> info@example.com
                    <br />
                  </p>
      
              </div>
              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right" /> <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <Link to="/stadium">Stadium</Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <Link to="/flexipack">Flexi Pack</Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"/> <Link to="/printedtape">Printed Tape</Link>
                  </li>
             
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Follow Us</h4>
                <div className="social-links mt-3">
                  {/* <Link to="#" className="twitter">
                    <i className="bx bxl-twitter" />
                  </Link> */}
                              <Link to="https://www.facebook.com/" className="facebook" target='blank'>
                <i className="bx bxl-facebook" />
              </Link>
              <Link to="https://www.instagram.com/" className="instagram" target='blank'>
                <i className="bx bxl-instagram" />
              </Link>
              <Link to="https://www.skype.com/en/" className="google-plus" target='blank'>
                <i className="bx bxl-skype" />
              </Link>
              <Link to="https://in.linkedin.com/" className="linkedin" target='blank'>
                <i className="bx bxl-linkedin" />
              </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="copyright">
            © Copyright 
            <strong>
            <a href='https://infodazz.org/' target='_blank' rel="noreferrer"><span> Infodazz</span></a>
            </strong>
            . All Rights Reserved
          </div>
        </div>
      </footer>
      {/* End Footer */}
            </>
    )
}

export default Flxifooter
