import React ,{useState} from 'react';
import './Navbar.css';
import { Link,NavLink } from 'react-router-dom';

const Navbar = () => {
  const [click, setClick]= useState(false);
  const [isActive] = useState("");

  const handleClick = () => setClick(!click);

  const closeMobileMenu = () => setClick(false);
  return (
    <>
  <header id="header" className="fixed-top">
    <div className="container d-flex align-items-center justify-content-between">
      <h1 className="logo">
        <Link to="/">ANSO</Link>
      </h1>
  
      {/* <nav id="navbar" className="navbar"> */}
      <nav id="navbar" className={click? "navbar navbar-mobile" : "navbar"}>
        <ul>
          <li>
            <NavLink onClick={closeMobileMenu} className={`nav-link scrollto${isActive ? 'active' : ''}`} to="/">
              Home
            </NavLink>
          </li>
          <li>
            <NavLink  onClick={closeMobileMenu} className={`nav-link scrollto${isActive ? 'active' : ''}`} to="/stadium">
              Stadium
            </NavLink>
          </li>
          <li>
            <NavLink  onClick={closeMobileMenu} className={`nav-link scrollto${isActive ? 'active' : ''}`} to="/flexipack">
              Flexi Pack
            </NavLink>
          </li>
          <li>
            <NavLink  onClick={closeMobileMenu} className={`nav-link scrollto${isActive ? 'active' : ''}`} to="/printedtape">
              Printed Tape
            </NavLink>
          </li>
          <li>
            <NavLink className="getstarted scrollto" to="/contactus">
              Get Started
            </NavLink>
          </li>
        </ul>
        {/* <i onClick={handleClick} className="bi bi-list mobile-nav-toggle" /> */}
        <i onClick={handleClick} className={`bi ${click ? 'bi-x' : 'bi-list'} mobile-nav-toggle`} />
      </nav>
     
    </div>
  </header>
    </>
  )
}

export default Navbar;