import React, { useEffect} from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import Flxifooter from '../components/Flxifooter';
import { Link } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';

import './Flexipack.css';

function Printedtape() {
   
    useEffect(() => {
        Aos.init();
        Aos.refresh();
    }, [])

    return (
        <>
         {/* ======= Hero Section ======= */}
         <section id="hero">
                <Carousel>
                    <Carousel.Item style={{ backgroundImage: `URL(assets/img/tape/2.jpg)` }} interval={2000} >
                        <Carousel.Caption>
                            <div className='container'>
                                <h2 className="animate__animated animate__fadeInDown">
                                Our quality ensures your prosperity
                                </h2>
                                <p className="animate__animated animate__fadeInUp">
                                We provide standardized printable tape &amp; it is a complete choice of products for pressure
resistance. Our printing tape is designed to meet industry standards for quality, durability and
used for various packaging needs.
                                </p>
                                <Link
                                    to="#about"
                                    className="btn-get-started animate__animated animate__fadeInUp scrollto"
                                >
                                    Read More
                                </Link>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>

                    <Carousel.Item interval={3000} style={{ backgroundImage: `URL(assets/img/tape/4.webp)` }}>
                        <Carousel.Caption>
                            <div className='container'>
                                <h2 className="animate__animated animate__fadeInDown">
                                Printing tape’s quality is preciosity
                                </h2>
                                <p className="animate__animated animate__fadeInUp">
                                Anso printing tape ensures the safety and durability of your products. We offer a wide range of
                                options to suit different needs, including different sizes and adhesive strengths and are made
                                from high-quality materials.
                                </p>
                                <Link
                                    to="#about"
                                    className="btn-get-started animate__animated animate__fadeInUp scrollto"
                                >
                                    Read More
                                </Link>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </section>
  {/* End Hero */}
  <main id="main">
    {/* ======= About Section ======= */}
    <section id="about" className="about">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>About</h2>
          <p>About Us</p>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <img
              className="img-fluid rounded w-100 wow zoomIn"
              data-wow-delay="0.5s"
              src="assets/img/tape/1.jpg"
              alt='tapeimg1'
            />
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0">
            <p style={{textAlign:"justify"}}>
            Anso printing tape is designed to meet industry standards for quality and durability. It is a versatile solution that can be used for various packaging needs. The tape can easily be printed with custom designs, logos, or messages, making it a great branding tool. Designed to withstand high pressure and it provides a secure and professional seal for packages while also offering the ability to add customized graphics or text.
            </p>
            <p style={{textAlign:"justify"}}>
            Anso’s printing tape ensures the safety and durability of your products. We offer a wide range of options to suit different needs, including different sizes and adhesive strengths. Our printing tapes are made from high-quality materials, with long-lasting performance and reliability. Customized Printing Tape offers a unique and eye-catching way to promote your brand. With custom printing options, you can add your company logo, website, or any other desired information on the tape. This advertising tool helps your company stand out among competitors, increasing brand visibility and recognition. Anso Printing Tape is a cost-effective solution to promote your business.
            </p>
          </div>
        </div>
      </div>
    </section>
    {/* End About Section */}
    {/* ======= Cta Section ======= */}
    <section id="cta" className="cta">
      <div className="container" data-aos="zoom-in">
        <div className="text-center">
          <h3>We are glad to give voice to your issues</h3>
          <p>

          We are happy to have you here. If you have any queries or would like more information, please contact us. Feel free to get in touch with us for any questions, suggestions, or support needs.
          </p>

          
          {/* <a class="cta-btn" href="#">Call To Action</a> */}
        </div>
      </div>
    </section>
    {/* End Cta Section */}
    {/* ======= About Section ======= */}
    <section id="about" className="about">
      <div className="container" data-aos="fade-up">
        <div className="text-center">
          <h3>We deliver our custom printed tapes throughout India.</h3>
          <p>
            We do not cut costs by using poor quality substrates, we use premium
            materials. We use very strong glue. Tapes with acrylic adhesive or
            hot melt. We print the PP adhesive tape made of ecological
            materials!
          </p>
          <h6>
            <strong>
              Printed tapes are made of high quality material that is strong,
              ecologically produced.
            </strong>
          </h6>
          {/* <a class="cta-btn" href="#">Call To Action</a> */}
        </div>
      </div>
    </section>
    {/* End About Section */}
    {/* ======= About Section ======= */}
    <section id="about" className="about">
      <div className="container" data-aos="fade-up">
        <div className="row">
          <div className="col-lg-6">
            <img
              className="img-fluid rounded w-100 wow zoomIn"
              data-wow-delay="0.5s"
              src="assets/img/tape/9.jpg"
              alt='tapeimg2'
            />
          </div>
          <div className="col-lg-6 pt-lg-0">
            <p style={{textAlign:"justify"}}>
                Anso's superior quality printing tape is designed to withstand the rigors of shipping, ensuring that your packages arrive intact & undamaged. When it comes to packaging and shipping, using superior quality printing tape can make all the difference. Not only does it provide a secure seal for your packages, but it also adds a professional & polished look.
            </p>
            <p style={{textAlign:"justify"}}>
                Anso printing tape provides a reliable seal that won't peel or come undone during transit. Additionally, the printing on the tape allows you to easily identify your packages or add branding elements, making it not only functional but also a valuable marketing tool. Using Anso's superior quality printing tape is a small investment that can have a big impact for the protection of your packages. With advancements in technology, Anso printing tapes available that not only provides a strong adhesive but also boast durability. Anso tapes are designed to withstand various weather conditions & ensuring your packages remain securely sealed, even during long journeys. With Anso's long-lasting printing tape, you can have peace of mind knowing that your packages will arrive safely & professionally sealed.
            </p>
          </div>
        </div>
      </div>
    </section>
    {/* End About Section */}
  </main>
  {/* End #main */}
  <Flxifooter/>
</>
    )
}

export default Printedtape;
