import React ,{useEffect} from 'react';
import './Contact.css';
import Footer from '../components/Footer';
import Aos from 'aos';
import 'aos/dist/aos.css';


function Contact() {
  
  useEffect(() => {
    Aos.init();
    Aos.refresh();
}, [])

  return (
    <>
      <main id="main">
        {/* <!-- ======= Breadcrumbs ======= --> */}
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="container">
            <ol>
              <li><a href="index.html">Home</a></li>
              <li>Contact</li>
            </ol>
            <h2>Contact Details</h2>
          </div>
        </section>
        {/* <!-- End Breadcrumbs --> */}
        {/* <!-- ======= Contact Section ======= --> */}
        <section id="contact" className="contact section-bg">
          <div className="container" data-aos="fade-up">

            <div className="section-title">
              <h2>Contact</h2>
              <p>Contact Us</p>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="info-box">
                      <i className="bx bx-map"></i>
                      <h3>Our Address</h3>
                      <div className='row'>
                        <div className='col-md-6 col-sm-3'>
                          <p>
                            Anso Sports Academy <br />
                            995 B Gnanagiri Road,<br />
                            Sivakasi - 626123.<br />
                            <strong>Phone:</strong> +91 94887 75555 , 98433 55551
                            <br />
                            <strong>Email:</strong> info@example.com
                            <br />
                          </p>
                        </div>
                        <div className='col-md-6 col-sm-3'>
                          <p>
                            <span className='font-weight-bold'>Anso Flexi Pack</span> <br />
                            6 / 1442 B , Srivilliputhur Road,<br />
                            Satchiyapuram,
                            Sivakasi - 626 124. <br />
                            <strong>Phone:</strong> +91 98438 66661 , 04562-255553
                            <br />
                            <strong>Email:</strong> info@example.com
                            <br />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <div className="info-box mt-4">
                      <i className="bx bx-envelope"></i>
                      <h3>Email Us</h3>
                      <p>info@example.com<br />contact@example.com</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="info-box mt-4">
                      <i className="bx bx-phone-call"></i>
                      <h3>Call Us</h3>
                      <p>+1 5589 55488 55<br />+1 6678 254445 41</p>
                    </div>
                  </div> */}
                </div>

              </div>

            </div>

          </div>
        </section>
        {/* <!-- End Contact Section --> */}

      </main>
      {/* <!-- End #main --> */}
      <Footer/>
    </>
  )
}

export default Contact;
