import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './HeronoSlider.css';
import Aos from 'aos';
import 'aos/dist/aos.css';
function HeronoSlider() {

    useEffect(() => {
        Aos.init();
        Aos.refresh();
    }, [])

    return (
        <>
            {/* <!-- ======= Hero Section ======= --> */}
            <section id="heronoslider" className="heronoslider">
                <div className="container">
                    <div className="row gy-5" data-aos="fade-in">
                        <div className="col-lg-6 order-2 order-lg-2 d-flex flex-column justify-content-center text-center text-lg-start">
                            <h2>Welcome to <span style={{ background: "#ed502e", padding: "10px" }}>Anso</span></h2>
                            <p>We Provide Quality Services in Spacious Stadium and Producing High-Quality Flexi Packs, Printing Tapes.</p>

                            <div className="social-links">
                                    {/* <Link to="#" className="twitter"><i className="bi bi-twitter"></i></Link> */}
                                    <Link to="https://www.facebook.com/" className="facebook" target='blank'><i className="bi bi-facebook"></i></Link>
                                    <Link to="https://www.instagram.com/" className="instagram" target='blank'><i className="bi bi-instagram"></i></Link>
                                    <Link to="https://in.linkedin.com/" className="linkedin" target='blank'><i className="bi bi-linkedin"></i></Link>
                            </div>
                        </div>

       

                        {/* <div className="col-lg-6 order-1 order-lg-2 d-flex justify-content-center text-center">
                            <div className="row g-3">
                                <div className="col-5 text-start">
                                    <figure>
                                        <Link to="/stadium">
                                            <img className="img-fluid rounded w-100 wow zoomIn" src="assets/img/sports-man.jpg" alt='stadiumimg' />
                                        </Link>
                                        <figcaption className='w-100 text-center'>
                                            <Link to="/stadium">
                                                <h5>Stadium</h5>
                                            </Link>
                                        </figcaption>
                                    </figure>
                                </div>
                                <div className="col-5 text-start">
                                    <figure>
                                        <Link to="/printedtape">
                                            <img className="img-fluid rounded w-75 wow zoomIn" src="assets/img/printed-tape.jpg" alt='tapeimg' style={{ marginTop: "25%" }} />
                                        </Link>
                                        <figcaption className='w-75 text-center'>
                                            <Link to="/printedtape">
                                                <h5>Tapes</h5>
                                            </Link>
                                        </figcaption>
                                    </figure>
                                </div>
                                <div className="col-5 text-end">
                                    <figure>
                                        <Link to="/contactus">
                                            <img className="img-fluid rounded w-75 wow zoomIn" src="assets/img/anso.jpg" alt='ansoimg' />
                                        </Link>

                                    </figure>
                                </div>
                                <div className="col-5 text-center">
                                    <figure>
                                        <Link to="/flexipack">
                                            <img className="img-fluid rounded w-100 wow zoomIn" src="assets/img/flexi-pack.jpg" alt='fpackimg' />
                                        </Link>
                                        <figcaption>
                                            <Link to="/printedtape">
                                                <h5>Flexi Pack</h5>
                                            </Link>
                                        </figcaption>
                                    </figure>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-lg-6 order-1 order-lg-2 d-flex justify-content-center text-center">
                            <div className="row g-3">
                                <div className="col-5 text-start">

  
                                <figure>
                                        <Link to="/flexipack">
                                            <img className="img-fluid rounded w-100 wow zoomIn" src="assets/img/flexi-pack.jpg" alt='fpackimg' />
                                        </Link>
                                        <figcaption className='w-100 text-center'>
                                            <Link to="/flexipack">
                                                <h5>Flexi Pack</h5>
                                            </Link>
                                        </figcaption>
                                    </figure>


                                   
                                </div>
                                <div className="col-5 text-start">
                                    <figure>
                                    <Link to="/stadium">
                                            <img className="img-fluid rounded w-75 wow zoomIn" src="assets/img/sports-man.jpg" alt='stadiumimg' style={{ marginTop: "29%" }} />
                                        </Link>
                                        <figcaption className='w-75 text-center'>
                                        <Link to="/stadium">
                                                <h5>Stadium</h5>
                                            </Link>
                                        </figcaption>
                                    </figure>
                                </div>
                                <div className="col-5 text-end">
                                    <figure>
                                        <Link to="/contactus">
                                            <img className="img-fluid rounded w-75 wow zoomIn" src="assets/img/anso.jpg" alt='ansoimg' />
                                        </Link>

                                    </figure>
                                </div>
                                <div className="col-5 text-center">

                                <figure>
                                    <Link to="/printedtape">
                                        <div className="content-overlay-small"></div>
                                            <img className="img-fluid rounded w-100 wow zoomIn" src="assets/img/printed-tape.jpg" alt="tapeimg" />
                                        </Link>
                                        
                                        <figcaption className='w-100 text-center'>
                                        <Link to="/printedtape">
                                                <h5>Tapes</h5>
                                            </Link>
                                        </figcaption>
                                        
                                    </figure>


                                </div>
                            </div>
                        </div>



                    </div>

                </div>

            </section>
            {/* <!-- End Hero Section --> */}

        </>
    )
}

export default HeronoSlider
