import React, { useEffect, useState } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import Flxifooter from '../components/Flxifooter';
import { Link } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCircleChevronLeft,
    faCircleChevronRight,
    faCircleXmark
} from '@fortawesome/free-solid-svg-icons'
import './Flexipack.css';

function Flexipack() {
    const galleryImages = [
        
        {
            img: "assets/img/fpack/1.jpg"
        },
        {
            img: "assets/img/fpack/2.jpg"
        },
        {
            img: "assets/img/fpack/15.jpg"
        },
        {
            img: "assets/img/fpack/16.jpg"
        },
        {
            img: "assets/img/fpack/3.jpg"
        },
        {
            img: "assets/img/fpack/4.jpg"
        },
        {
            img: "assets/img/fpack/5.jpg"
        },
        {
            img: "assets/img/fpack/6.jpg"
        },
        {
            img: "assets/img/fpack/7.jpg"
        },
        {
            img: "assets/img/fpack/8.jpg"
        },
       
        
    ]

    const [slideNumber, setSlideNumber] = useState(0)
    const [openModal, setOpenModal] = useState(false)

    const handleOpenModal = (index) => {
        setSlideNumber(index)
        setOpenModal(true)
    }

    // Close Modal
    const handleCloseModal = () => {
        setOpenModal(false)
    }

    // Previous Image
    const prevSlide = () => {
        slideNumber === 0
            ? setSlideNumber(galleryImages.length - 1)
            : setSlideNumber(slideNumber - 1)
    }

    // Next Image  
    const nextSlide = () => {
        slideNumber + 1 === galleryImages.length
            ? setSlideNumber(0)
            : setSlideNumber(slideNumber + 1)
    }

    useEffect(() => {

        Aos.init();
        Aos.refresh();

    }, [])



    return (
        <>
         {/* ======= Hero Section ======= */}

         <section id="hero">
                <Carousel>
                    <Carousel.Item style={{ backgroundImage: `URL(assets/img/fpack/4.jpg)` }} interval={2000} >
                        <Carousel.Caption>
                            <div className='container'>
                                <h2 className="animate__animated animate__fadeInDown">
                                Our quality fulfills your necessity
                                </h2>
                                <p className="animate__animated animate__fadeInUp">
                                Anso is the largest flexi packaging company, offering Standardized labels of various sizes for
                                sealing with all packing requirements. Innovative design of our flexi packs allows for easy
                                handling &amp; convenience in packaging.
                                </p>
                                <Link
                                    to="#about"
                                    className="btn-get-started animate__animated animate__fadeInUp scrollto"
                                >
                                    Read More
                                </Link>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>

                    <Carousel.Item interval={3000} style={{ backgroundImage: `URL(assets/img/fpack/5.jpg)` }}>
                        <Carousel.Caption>
                            <div className='container'>
                                <h2 className="animate__animated animate__fadeInDown">
                                Our quality increases your productivity
                                </h2>
                                <p className="animate__animated animate__fadeInUp">
                                The innovative design of our flexi packs allows for easy handling &amp; convenience in packaging
                                products. Our flexi packs are designed to be versatile &amp; flexible, making them suitable for
                                various industries and applications.
                                </p>
                                <Link
                                    to="#about"
                                    className="btn-get-started animate__animated animate__fadeInUp scrollto"
                                >
                                    Read More
                                </Link>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>

                    <Carousel.Item interval={4000} style={{ backgroundImage: `URL(assets/img/fpack/7.jpg)` }}>
                        <Carousel.Caption>
                            <div className='container'>
                                <h2 className="animate__animated animate__fadeInDown">
                                Our quality ensures your quantity
                                </h2>
                                <p className="animate__animated animate__fadeInUp">
                                With our advanced resources, we provide advanced quality flexi packs to various commercial
                                clients with complete quality assurance and easily are labeled with custom designs or messages,
                                making it a great branding tool.
                                </p>
                                <Link
                                    to="#about"
                                    className="btn-get-started animate__animated animate__fadeInUp scrollto"
                                >
                                    Read More
                                </Link>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>

                </Carousel>
            </section>

  {/* End Hero */}
  <main id="main">
    {/* ======= About Section ======= */}
    <section id="about" className="about">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>About</h2>
          <p>About Us</p>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="row g-3">
              <div className="col-6 text-start">
                <img
                  className="img-fluid rounded w-100 wow zoomIn"
                  data-wow-delay="0.1s"
                  src="assets/img/fpack/img1.jpg"
                  alt='fpackimage1'
                />
              </div>
              <div className="col-6 text-start">
                <img
                  className="img-fluid rounded w-75 wow zoomIn"
                  data-wow-delay="0.3s"
                  src="assets/img/fpack/img2.jpg"
                  style={{ marginTop: "25%" }}
                  alt='fpackimage2'
                />
              </div>
              <div className="col-6 text-end">
                <img
                  className="img-fluid rounded w-75 wow zoomIn"
                  data-wow-delay="0.5s"
                  src="assets/img/fpack/img3.jpg"
                  alt='fpackimage3'
                />
              </div>
              <div className="col-6 text-end">
                <img
                  className="img-fluid rounded w-100 wow zoomIn"
                  data-wow-delay="0.7s"
                  src="assets/img/fpack/img4.jpg"
                  alt='fpackimage4'
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0">
            <p style={{textAlign:"justify"}}>
            Anso Flexi Pack offers a wide range of packaging materials to suit different industries. These are
            designed to provide superior protection against moisture, UV rays, and environmental
            contaminants. Our packaging materials are known for high quality, ensuring that your products
            are well-protected during storage and transportation. As a leading manufacturer and supplier, we
            prioritize customer satisfaction and strive to deliver products that meet the highest industry
            standards.
            </p>
            <p style={{textAlign:"justify"}}>
            The innovative design of our flexi packs allows for easy handling and convenience in packaging
            and storing products. The flexi packs are designed to be versatile and flexible, making them
            suitable for various industries and applications. We prioritize the quality of our products,
            ensuring they meet the highest standards. Our aim is to produce products in the least amount of
            time, without compromising on quality. We offer a good price performance ratio, providing
            customers with value for their money. We strive to meet and exceed customer expectations by
            delivering high-quality products efficiently and at a competitive price.
            </p>
          </div>
        </div>
      </div>
    </section>
    {/* End About Section */}
    {/* ======= Portfolio Section ======= */}
    <div>
    {openModal &&
                        <div className='sliderWrap'>
                            <FontAwesomeIcon icon={faCircleXmark} className='btnClose' onClick={handleCloseModal} />
                            <FontAwesomeIcon icon={faCircleChevronLeft} className='btnPrev' onClick={prevSlide} />
                            <FontAwesomeIcon icon={faCircleChevronRight} className='btnNext' onClick={nextSlide} />
                            <div className='fullScreenImage'>
                                <img src={galleryImages[slideNumber].img} alt='' />
                            </div>
                        </div>
                    }
    <section id="portfolio" className="portfolio">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Our Products</h2>
          <p>Check our Products</p>
        </div>
        
      </div>

      <div className="container" data-aos="fade-up">
                        <div class="row fix gallery">
                        {
                            galleryImages && galleryImages.map((slide, index) => {
                                return (
                                    <div key={index}
                                        onClick={() => handleOpenModal(index)} className="col-lg-3 col-md-4 col-xs-6 thumb">
                                        <div class="gallery-item h-100">
                                            <img src={slide.img} class="img-fluid" alt="" />
                                        </div>
                                    </div>
                                )
                            })
                        }
                        </div>
                        </div>

    </section>
    </div>
    {/* End Portfolio Section */}
  </main>
  {/* End #main */}
  <Flxifooter/>
        </>
    )
}

export default Flexipack
