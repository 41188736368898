import React from 'react'
import HeronoSlider from './components/HeronoSlider';

const Home = () => {
  return (
    <>
    <HeronoSlider/>

    </>
  )
}

export default Home