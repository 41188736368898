import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Home from './Home';
import Stadium from './Pages/Stadium';
import Flexipack from './Pages/Flexipack';
import Printedtape from './Pages/Printedtape';
import Contact from './Pages/Contact';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


function App() {

  return (
    <>
    <Router>
      <Navbar/>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path="/stadium" element={<Stadium/>} />
        <Route path="/flexipack" element={<Flexipack/>}/>
        <Route path="/printedtape" element={<Printedtape/>}/>
        <Route path="/contactus" element={<Contact/>}/>
      </Routes>
    </Router>
    </>
  );
}

export default App;
