import React, { useEffect, useState } from 'react';
import './Stadium.css';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import Footer from '../components/Footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCircleChevronLeft,
    faCircleChevronRight,
    faCircleXmark
} from '@fortawesome/free-solid-svg-icons'

function Stadium() {
    const galleryImages = [
        {
            img: "assets/img/sports/1.jpg"
        },
        {
            img: "assets/img/sports/2.jpg"
        },
        {
            img: "assets/img/sports/3.jpg"
        },
        {
            img: "assets/img/sports/4.jpg"
        },

        {
            img: "assets/img/sports/5.jpg"
        },
        {
            img: "assets/img/sports/6.jpg"
        },
        {
            img: "assets/img/sports/7.jpg"
        },

        {
            img: "assets/img/sports/8.jpg"
        },
        {
            img: "assets/img/sports/9.jpg"
        },
        {
            img: "assets/img/sports/10.jpg"
        },
        {
            img: "assets/img/sports/11.jpg"
        },
        {
            img: "assets/img/sports/12.jpg"
        }





    ]

    const [slideNumber, setSlideNumber] = useState(0)
    const [openModal, setOpenModal] = useState(false)

    const handleOpenModal = (index) => {
        setSlideNumber(index)
        setOpenModal(true)
    }

    // Close Modal
    const handleCloseModal = () => {
        setOpenModal(false)
    }

    // Previous Image
    const prevSlide = () => {
        slideNumber === 0
            ? setSlideNumber(galleryImages.length - 1)
            : setSlideNumber(slideNumber - 1)
    }

    // Next Image  
    const nextSlide = () => {
        slideNumber + 1 === galleryImages.length
            ? setSlideNumber(0)
            : setSlideNumber(slideNumber + 1)
    }

    useEffect(() => {

        Aos.init();
        Aos.refresh();

    }, [])

    return (
        <>

            <section id="hero">
                <Carousel id="heroCarousel" >
                    {/* <Carousel.Item  style={{ backgroundImage: `URL(assets/img/banner/sports.jpg)` }} interval={2000} > */}
                    <Carousel.Item  style={{ backgroundImage: `URL(assets/img/banner/sports.jpg)` }}>
                        <Carousel.Caption>
                            <div className='container'>
                                <h2 className="animate__animated animate__fadeInDown">
                                Our creativity ensures your eternity
                                </h2>
                                <p>
                                With spacious seating arrangements &amp; state-of-the-art facilities, stadium offers Badminton,
                                Basketball, Volleyball, Football &amp; other indoor games. Our spacious stadium has more than
                                10,000 square feet with AC coverage.
                                </p>
                                <Link
                                    to="#about"
                                    className="btn-get-started animate__animated animate__fadeInUp scrollto"
                                >
                                    Read More
                                </Link>
                            </div>
                        
                        </Carousel.Caption>
                        
                    </Carousel.Item>

                    <Carousel.Item interval={3000} style={{ backgroundImage: `URL(assets/img/banner/sports-banner2.jpg)` }}>
                        <Carousel.Caption>
                            <div className='container'>
                                <h2 className="animate__animated animate__fadeInDown">
                                Our immensity helps your prosperity
                                </h2>
                                <p className="animate__animated animate__fadeInUp">
                                Anso Stadium is a stunning piece of architecture that captures the imagination. A venue is
aesthetically pleasing that has been created through extraordinary design. We have designated
spacious areas for all indoor games.
                                </p>
                                <Link
                                    to="#about"
                                    className="btn-get-started animate__animated animate__fadeInUp scrollto"
                                >
                                    Read More
                                </Link>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>

                    <Carousel.Item interval={4000} style={{ backgroundImage: `URL(assets/img/banner/sports-banner3.jpg)` }}>
                        <Carousel.Caption className='carousel-container'>
                            <div className='container'>
                                <h2 className="animate__animated animate__fadeInDown">
                                Our prosperity helps your superiority
                                </h2>
                                <p className="animate__animated animate__fadeInUp">
                                We provide ample room for a comfortable experience and the stunning landscape surrounding
                                the stadium adds to the overall charm of the venue &amp; is designed to accommodate large crowds,
                                ensuring enjoyments to the fullest.
                                </p>
                                <Link
                                    to="#about"
                                    className="btn-get-started animate__animated animate__fadeInUp scrollto"
                                >
                                    Read More
                                </Link>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>

                </Carousel>
            </section>

            <main id="main">
                {/* ======= About Section ======= */}
                <section id="about" className="about">
                    <div className="container" data-aos="fade-up">
                        <div className="section-title">
                            <h2> Stadia</h2>
                            <p>For stadia spectators</p>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="row g-3">
                                    <div className="col-6 text-start">
                                        <img
                                            className="img-fluid rounded w-100 wow zoomIn"
                                            data-wow-delay="0.1s"
                                            src="assets/img/About/sp2.jpg"
                                            alt='sportsimg1'
                                        />
                                    </div>
                                    <div className="col-6 text-start">
                                        <img
                                            className="img-fluid rounded w-75 wow zoomIn"
                                            data-wow-delay="0.3s"
                                            src="assets/img/banner/sports-banner2.jpg"
                                            style={{ marginTop: "25%" }}
                                            alt='sportsimg2'
                                        />
                                    </div>
                                    <div className="col-6 text-end">
                                        <img
                                            className="img-fluid rounded w-75 wow zoomIn"
                                            data-wow-delay="0.5s"
                                            src="assets/img/banner/sports-banner3.jpg"
                                            alt='sportsimg3'
                                        />
                                    </div>
                                    <div className="col-6 text-end">
                                        <img
                                            className="img-fluid rounded w-100 wow zoomIn"
                                            data-wow-delay="0.7s"
                                            src="assets/img/About/sp.jpg"
                                            alt='sportsimg4'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 pt-4 pt-lg-0">
                                <p style={{textAlign:"justify"}}>
                                Anso is a top-notch organization. We have standardized Spacious Stadium under area spans over
                                10,000 square feet with AC coverage. The atmosphere is pleasant with an exquisite lobby,
                                beautifully designed Interiors &amp; the best coaches making your visit to Anso a surreal experience.
                                </p>
                                <p style={{textAlign:"justify"}}>
                                Anso Stadium is a showcasing remarkable architecture that captivates the imagination.
                                Extraordinary design has created a venue that is both aesthetically pleasing and functional. With
                                spacious seating arrangements &amp; state-of-the-art facilities, stadium offers Badminton, Basketball
                                &amp; other indoor games. The Spacious Stadium provides ample room for a comfortable and
                                enjoyable experience ensuring everyone enjoy the event to the fullest. The stunning landscape
                                surrounding the stadium adds to the overall charm of the venue. Whether you&#39;re a player or a
                                spectator, the Spacious Stadium offers an immersive and unforgettable experience. The venue is
                                designed to accommodate large crowds, ensuring everyone can enjoy the event to the fullest. Our
                                Spacious Stadium is the perfect destination for sporting events.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                {/* End About Section */}
                {/* ======= Cta Section ======= */}
                <section id="cta" className="cta">
                    <div className="container" data-aos="zoom-in">
                        <div className="text-center">
                            <h3>We are here to make your issues hear</h3>
                            <p>
                                We are glad to have you around. We are here to answer any questions or provide further information. Feel free to contact us for any inquiries, feedback, or support you need.
                            </p>
                            <Link className="cta-btn" to="#">
                                Get Started
                            </Link>
                        </div>
                    </div>
                </section>
                {/* End Cta Section */}


                <div>

                    {openModal &&
                        <div className='sliderWrap'>
                            <FontAwesomeIcon icon={faCircleXmark} className='btnClose' onClick={handleCloseModal} />
                            <FontAwesomeIcon icon={faCircleChevronLeft} className='btnPrev' onClick={prevSlide} />
                            <FontAwesomeIcon icon={faCircleChevronRight} className='btnNext' onClick={nextSlide} />
                            <div className='fullScreenImage'>
                                <img src={galleryImages[slideNumber].img} alt='' />
                            </div>
                        </div>
                    }

                    {/* ======= Portfolio Section ======= */}
                    <section id="portfolio" className="portfolio">
                        <div className="container" data-aos="fade-up">
                            <div className="section-title">
                                <h2>Gallery</h2>
                                <p>Stadia Spectators Gallery</p>
                            </div>
                        </div>
                        <div className="container" data-aos="fade-up">
                        <div class="row fix gallery">
                        {
                            galleryImages && galleryImages.map((slide, index) => {
                                return (
                                    <div key={index}
                                        onClick={() => handleOpenModal(index)} className="col-lg-3 col-md-4 col-xs-6 thumb">
                                        <div class="gallery-item h-100">
                                            <img src={slide.img} class="img-fluid" alt="" />
                                        </div>
                                    </div>
                                )
                            })
                        }
                        </div>
                        </div>
                    </section>
                    {/* End Portfolio Section */}
                </div>
            </main>
            {/* End #main */}
            <Footer />
        </>
    )
}

export default Stadium
